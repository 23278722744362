import { useNavigator } from "~/hooks/navigator/useNavigator";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  type FunctionComponent,
} from "react";
import { config } from "~/global.config";

type BackProps = { fallbackRoute?: string; skipSearchParams?: boolean };

export interface HistoryContext {
  back: (props: BackProps) => void;
  entries: URL[];
}

const HistoryContext = createContext({} as HistoryContext);

export interface HistoryProviderProps {
  ignoredRouteFrags?: string[];
  limit?: number;
  children: ReactNode;
}

export const HistoryProvider: FunctionComponent<HistoryProviderProps> = ({
  children,
  ignoredRouteFrags,
  limit,
}) => {
  const { navigateTo, basePath, asPath } = useNavigator();
  const [history] = useState<URL[]>([]);

  const back = useCallback(
    ({ fallbackRoute, skipSearchParams }: BackProps) => {
      while (history.length) {
        const current = new URL(basePath + asPath, config.origin);
        const past = history.pop();

        if (past.origin !== config.origin) {
          continue;
        }

        if (skipSearchParams) {
          current.search = "";
          past.search = "";
        }

        if (current.href === past.href) {
          continue;
        }

        const route = past.pathname.slice(basePath.length) + past.search;
        navigateTo(route);
        return;
      }

      if (!history.length) {
        navigateTo(fallbackRoute ?? "/");
      }
    },
    [history, navigateTo, basePath, asPath],
  );

  useEffect(() => {
    const ignore = (
      ignoredRouteFrags ?? ["#", "/onboarding", "/500", "/502", "/404", "/403"]
    ).find((frag) => asPath.includes(frag));

    if (ignore) return;

    while (history.length >= limit ?? 50) {
      history.shift();
    }

    history.push(new URL(basePath + asPath, config.origin));
  }, [history, ignoredRouteFrags, limit, asPath, basePath]);

  return (
    <HistoryContext.Provider
      value={{
        back,
        entries: history,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export function useHistory(): HistoryContext {
  const context = useContext(HistoryContext);
  return context;
}
