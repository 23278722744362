import Script from 'next/script';
import React, { createContext, useState, useEffect, useContext } from 'react';

/**
 *  Cookie consent is using an open source library from https://brainsum.github.io/cookieconsent/
 */

interface CookieConsent {
  isInitialized: boolean;
}

const CookieConsentContext = createContext<CookieConsent>({} as CookieConsent);

export const CookieConsentProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [cookieConsent, setCookieConsent] = useState<any>(null);
  const [isInitialized, setInitialized] = useState<boolean>(false);

  useEffect(() => {
    if (!cookieConsent) return;

    const config = require("./cookieConsent.config").cookieConsentConfig;

    cookieConsent?.init(config);
    setInitialized(true);
  }, [cookieConsent]);

  return (
    <CookieConsentContext.Provider value={{ isInitialized }}>
      {children}

      <Script
        src="/guest/js/cookieconsent.min.js"
        onLoad={() => setCookieConsent(window.CookieConsent)}
        strategy="lazyOnload"
        defer
      />
    </CookieConsentContext.Provider>
  );
};

export function useCookieConsent(): CookieConsent {
  const context = useContext(CookieConsentContext);
  return context;
}
