import { useNavigator } from "~/hooks/navigator/useNavigator";
import type { RudderAnalytics } from "@rudderstack/analytics-js";

import React, { createContext, useContext, useEffect, useState } from "react";
import { useMe } from "~/hooks/useMe";
import { TRACKING_EVENTS } from "./event-names";

interface Analytics {
  identify(userId: string, traits?: { [key: string]: any }): void;
  group(groupId: string, traits?: { [key: string]: any }): void;
  page(
    category?: string,
    name?: string,
    properties?: { [key: string]: any },
  ): void;
  bookingInitiated(properties?: { [key: string]: any }): void;
  finishBookingClicked(properties?: { [key: string]: any }): void;
  userSignedUp(properties?: { [key: string]: any }): void;
  bookmarkAdded(properties?: { [key: string]: any }): void;
  bookmarkRemoved(properties?: { [key: string]: any }): void;
  accessKeyClicked(properties?: { [key: string]: any }): void;
  bookingShared(properties?: { [key: string]: any }): void;
  shareClicked(properties?: { [key: string]: any }): void;
}

const AnalyticsContext = createContext<Analytics>({} as Analytics);

export const AnalyticsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { pathname } = useNavigator();
  const { me, organization } = useMe();
  const [analytics, setAnalytics] = useState<RudderAnalytics>();

  useEffect(() => {
    if (!analytics) {
      const initialize = async () => {
        const { RudderAnalytics } = await import("@rudderstack/analytics-js");
        const analyticsInstance = new RudderAnalytics();

        analyticsInstance.load(
          process.env.NEXT_PUBLIC_RUDDERSTACK_WRITE_KEY,
          process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL,
          {
            anonymousIdOptions: {
              autoCapture: {
                enabled: true,
                source: "segment",
              },
            },
          },
        );

        analyticsInstance.ready(() => {
          console.debug("Analytics is all set!");
        });

        setAnalytics(analyticsInstance);
      };

      initialize().catch((e) => console.log(e));
    }
  }, [analytics]);

  useEffect(() => {
    if (me && analytics) {
      analytics.identify(me.id, {
        name: me.profile?.firstName + " " + me.profile?.lastName,
        email: me.email,
      });
      if (organization) {
        analytics.group(organization?.id, { name: organization?.name });
      }
      analytics.track(TRACKING_EVENTS.OPENED_APP);
    }
  }, [me, me?.profile, organization]);

  useEffect(() => {
    if (analytics) {
      analytics.page(pathname);
    }
  }, [pathname]);

  const track = (eventName: string, properties?: { [key: string]: any }) => {
    if (analytics) {
      analytics.track(eventName, properties);
    }
  };

  const identify = (userId: string, traits?: { [key: string]: any }) => {
    if (analytics) {
      analytics.identify(userId, traits);
    }
  };

  const group = (groupId: string, traits?: { [key: string]: any }) => {
    if (analytics) {
      analytics.group(groupId, traits);
    }
  };

  const page = (
    category: string,
    name: string,
    properties?: { [key: string]: any },
  ) => {
    if (analytics) {
      analytics.page(category, name, properties);
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        identify: (userId, traits) => identify(userId, traits),
        group: (groupId, traits) => group(groupId, traits),
        page: (category, name, properties) => page(category, name, properties),
        bookingInitiated: (properties) =>
          track(TRACKING_EVENTS.BOOKING_INITIATED, properties),
        finishBookingClicked: (properties) =>
          track(TRACKING_EVENTS.FINISH_BOOKING_CLICKED, properties),
        userSignedUp: (properties) =>
          track(TRACKING_EVENTS.USER_SIGNEDUP, properties),
        bookmarkAdded: (properties) =>
          track(TRACKING_EVENTS.BOOKMARK_ADDED, properties),
        bookmarkRemoved: (properties) =>
          track(TRACKING_EVENTS.BOOKMARK_REMOVED, properties),
        accessKeyClicked: (properties) =>
          track(TRACKING_EVENTS.ACCESSKEY_CLICKED, properties),
        bookingShared: (properties) =>
          track(TRACKING_EVENTS.BOOKING_SHARED, properties),
        shareClicked: (properties) =>
          track(TRACKING_EVENTS.SHARE_CLICKED, properties),
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export function useAnalytics(): Analytics {
  const context = useContext(AnalyticsContext);
  return context;
}
